/*
BRAND COLORS

white: #FFFFFF
orange: #FF9900
light navy: #232F3E
dark navy: #161E2D
*/

.container{
  padding-top:15px;
  position: relative;
  min-height: 94vh;
}

.header wrap{
  margin: 0 auto;
  width:100%;
}

.content-wrap{
  padding-bottom: 100px;
}

.hero {
  margin-top:25px;
  margin-bottom: 50px;
}

.bg-pattern {
  background-image: url("images/dot-texture.png");
  background-repeat: no-repeat;
  position: absolute;
  /*background-size: 100% 900px;*/
  height:100%;
  width:50%;
  top:0px;
  left:80%;
  opacity: 0.5;
  z-index:-1;
  margin: 50 auto 0 auto;
  overflow: hidden;
}

.hero img {
  padding-top:15px;
}

body {
  background-color: #161E2D;
  margin: 0;
  padding: 0;
  font-family: "Amazon Ember", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  color: white;
}

h5 {
  font-weight: 700;
  font-size: 1.5rem;
}

p {
  color: white;

}

canvas{
  position: absolute;
  top:0;
  left:0;
  z-index:-1;
}

.card {
  /*height: 310px;*/
  width: 18rem;
  border: 2px solid #FF9900;
  background-color: #232F3E;
  margin:15px;
  
  box-shadow: 0rem 0 1rem rgb(7, 5, 6);
  transition: 0.4s ease-out;
  /*position: relative;*/
  /*left: 0px;*/

  box-sizing: border-box;
  display: inline-block;
  float: none;
  text-align:left;
}

.card + .card {
  margin-left: 2%;
}

/*
.card:not(:first-child) {
  margin-left: 20px;
}
*/
.flex-container{
  webkit-justify-content: center;
  text-align: center;
  padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar {
  width: 100%;
  height: 3px;
  margin-bottom:5px;
}

.emptybar {
  background-color: #2e3033;
  width: 100%;
  height: 3px;
  margin-bottom: 4px;
}

.filledbar {
  position: relative;
  margin-top:-8px;
  z-index: 3;
  width: 0px;
  height: 3px;
  /*background: rgb(0,154,217);*/
  background: linear-gradient(90deg, rgb(255, 110, 127) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
  /* blue to yellow 
  background: linear-gradient(90deg, rgba(0,154,217,1) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
  */
  transition: 0.5s ease-out;
}

.titlebar {
  display: block;
  position: relative;
  background: linear-gradient(90deg, rgb(255, 110, 127) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
  width: 45%;
  height: 4px;
  left: -15px;
  margin-bottom: 10px;
}

.card:hover .filledbar {
  width: 100%;
  transition: 0.2s ease-out;
}

.btn-info {
  color: white;
  background-color:transparent;
  border-color:#FF9900;
  margin-right: 15px;
}

.btn-info:hover {
  background-color:#FF9900;
  color: white;
  border-color:#FF9900;
  box-shadow: #FF9900;
}

.btn-info:active,
.btn-info:active:focus,
.btn-info:visited {
  background-color:#FF9900;

}

pre code {
  display: block;
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.td, tr{
  color:white;
}

.footer {
  left:0px;
  bottom:0px;
  margin-top:70px;
  width:100%;
  background:#161E2D;
  position: inline-block;
}

.footer p{
  color:gray;
}

.footer-demo {
  left:0px;
  bottom:0px;
  margin-top:75px;
  width:100%;
  background:#161E2D;
  position:absolute;
}
.footer-demo p{
  color:gray;
}

/* accordion styles on Comprehend page */
body {
  padding: 0 1em 1em;
}

/* hideable radio button input */
input hidden {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: flex;
}
.row .col {
  flex: 1;
}

.row .col:last-child {
  margin-left: 1em;
}

/* Accordion styles */
/*

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: flex;
}
.row .col {
  flex: 1;
}
.row .col:last-child {
  margin-left: 1em;
}
*/
/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #2c3e50;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0px;
  /* Icon */
}
.tab-label:hover {
  background: #1a252f;
}
.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #4f84b9;
  background: white;
  transition: all .35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #4f84b9;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}

input:checked + .tab-label {
  background: #1a252f;
}
input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

/* component navbar */

menu{
  margin-bottom:50px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

span li {
  display: inline;
  margin-right: 1.5rem;
}

.item{
  color:lightgray;
}

.item a{
  text-decoration:none;
}
.item:hover{
  color:#F8991d;
  text-decoration:none;
}

.ui.pointing.secondary.menu{
  background: rgb(0,0,0,0);
  position:relative;
  margin-top: 1em;
  margin-bottom: 1em;
  height:1.5em;
  margin-right: auto;
  margin-left: auto;
}